@import "../../../../../main.module.scss";

.main {
  width: 100%;
  position: relative;

  .wrapperFull {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 60px 0;
    height: 100%;


    .wrapperImgTop {
      width: 100%;

      img {
        width: 100%;
        height: auto;
        border-radius: 16px;

      }

    }

    .wrapperImgBottom {
      width: 85%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      gap: 5%;
      margin-top: -20%;
      transform: translate(15%, 0);

      @media (max-width: 950px) {
        width: 100%;
        transform: none;
        align-items: center;
        justify-content: center;

      }

      .wrapperDescription {
        width: 80%;
        height: auto;
        background-color: white;
        border-radius: 16px;
        padding: 30px 20px;
        box-shadow: 0px -1px 5px 0px rgba(94, 94, 94, 0.75);

        @media (max-width: 900px) {
          width: 100%;
          border-radius: 0;

        }

        h2 {
          text-align: center;
          margin-bottom: 20px;
          font-weight: 700;


          @media (max-width: 600px) {
            font-size: 20px;

          }

          @media (max-width: 470px) {
            font-size: 16px;

          }

        }

        p {
          margin-bottom: 15px;

          @media (max-width: 600px) {
            font-size: 14px;


          }

          @media (max-width: 470px) {
            font-size: 12px;

          }

        }

        button {
          padding: 10px 20px;
          border-radius: 16px;
          background-color: $blue;
          color: white;
          border: none;
          cursor: pointer;
        }

      }

      .wrapperImgForImgBottom {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;

        @media (max-width: 950px) {
          display: none;

        }

        img {
          width: 50%;
          object-fit: cover;
          border-radius: 16px;

        }

      }
    }

    .backgroundBlue {
      position: absolute;
      right: 0;
      top: 0;
      width: 60%;
      height: 100%;
      border-radius: 32px 0 0 32px;
      background-color: $blue;
      z-index: -1;
    }

  }
}