$max-width: 1280px;

$blue: #2d2d69;
$red: #dc3545;
$color-p: gray;

@mixin responsive-padding-no-background {
  padding: 40px 80px;

  @media (max-width: 800px) {
    padding: 10% 5% 10%;
  }
}

@mixin widthWithMarginCenter {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
}
