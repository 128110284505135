.main {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;

  h2 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 60px;

  }

  .wrapperFullContent {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    display: flex;

    @media (max-width: 870px) {
      flex-direction: column;


    }



    @media (max-width: 1140px) {
      padding: 0 5%;

    }


    .wrapperForAvatarForFounder {
      width: 50%;

      @media (max-width: 870px) {
        width: 100%;

      }

      img {
        width: 100%;
        height: auto;
        border-radius: 16px;
      }

    }

    .wrapperDescription {
      width: 50%;
      padding-left: 10%;

      @media (max-width: 870px) {
        width: 100%;
        padding-left: 2.5%;
        padding-top: 50px;

      }

      ul {

        li {
          width: 117%;
          font-size: 22px;
          color: #1e1e1e;
          font-weight: 600;
          display: flex;
          transform: translate(-13.5%, 0);

          @media (max-width: 1140px) {
            font-size: 18px;

          }

          @media (max-width: 980px) {
            font-size: 16px;

          }

          @media (max-width: 870px) {
            font-size: 18px;
            transform: translate(0, 0);
            width: 100%;
            list-style: disc;

          }

          .wrapperIcons {
            display: flex;
            align-items: center;

            @media (max-width: 870px) {
              display: none;
            }

          }


        }

        hr {
          margin: 12px 0;
        }
      }
    }
  }

  .ancillaryDescription {
    max-width: 1140px;
    width: 100%;
    margin: 60px auto 0 auto;

    p {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }

  }
}