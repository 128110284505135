@import "../../../main.module.scss";

.main {
  max-width: $max-width;
  width: 100vw;
  min-height: 100vh;
  padding: 40px 0;
  margin: 0 auto;

  @media (max-width: 1280px) {
    @include responsive-padding-no-background;

  }

  h1 {
    margin-bottom: 20px;
  }

  div {
    p {
      margin-bottom: 16px;

      ol {
        margin: 20px 0;
      }

      span {
        color: orange;
        margin: 15px;

        &::before {
          content: "";

        }
      }
    }
  }


}