@import "../../../../../main.module.scss";

.main {
  @include widthWithMarginCenter;
  @include responsive-padding-no-background;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .skillList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;

    .skill {
      width: 32%;
      margin-bottom: 2%;
      display: flex;
      align-items: center;

      @media (max-width: 1024px) {
        width: 49%;

      }

      @media (max-width: 650px) {
        width: 100%;

      }

      .img {
        padding: 20px;

        img {
          width: 56px;
          height: 56px;
          object-fit: contain;
          border-radius: 16px;
        }
      }

      h3 {

      }
    }
  }
}