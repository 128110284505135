@import "../../../../../main.module.scss";

.wrapperMain {
  width: 100%;
  background-color: $blue;

  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;

  }

  .main {
    width: calc(50% - 20px);
    max-width: $max-width;
    margin: 0 auto;
    height: auto;
    padding: 80px 0 0 40px;
    border-radius: 24px;
    display: flex;

    @media (max-width: 700px) {
      width: 100%;
      flex-direction: column;
      padding: 80px 40px 0 40px;

    }

    * {
      color: white;
    }

    .wrapperDescriptionCourses {
      width: 100%;
      padding-bottom: 40px;

      @media (max-width: 700px) {
        padding-bottom: 0;

      }

      h3 {
        margin-bottom: 10px;
        font-size: 18px;

      }

      h1 {
        font-size: 32px;
        margin-bottom: 10px;

      }

      p {
        margin-bottom: 10px;
      }

      ul {
        list-style: disc;
        padding-left: 15px;
        margin-top: 20px;

        li {
          list-style: disc;
          margin-bottom: 10px;
        }
      }

      button {
        border-radius: 8px;
        padding: 10px 20px;
        font-weight: 600;
        border: none;
        background-color: $red;
      }

    }

    .error {
      color: black;
    }

  }

  .wrapperImg {
    width: 50%;

    @media (max-width: 700px) {
      width: 100%;

    }

    img {
      width: 100%;
      height: auto;
      max-height: 70vh;
      object-fit: contain;
    }
  }
}