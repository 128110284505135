@import "../../../../../main.module.scss";

.wrapperMain {
  width: 100%;
  position: relative;

  .semicircle {
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 40vh;
    background-color: $red;
    border-radius: 50%;
    transform: translate(0, -50%);

    @media (max-width: 800px) {
      height: 55vh;
    }

    @media (max-width: 500px) {
      border-radius: 30%;
    }

  }


  .main {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    @include responsive-padding-no-background;


    h2 {
      text-align: center;
      color: white;
      font-size: 36px;
    }

    .wrapperForBenefits {
      display: flex;
      gap: 20px;
      margin-top: 20px;
      overflow-x: auto;
      padding: 20px;

      .cardBenefits {
        background-color: white;
        min-width: 300px;
        width: 300px;
        height: auto;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.75);


        h3 {
          margin-bottom: 10px;
        }

        * {
          color: black;
        }

      }
    }
  }
}