@import "../../../../../main.module.scss";

.main {
  max-width: $max-width;
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;

  @media (max-width: 1280px) {
    @include responsive-padding-no-background;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .title {
    width: 50%;
    padding-right: 40px;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
      width: 100%;
    }

    h2 {
      margin-bottom: 10px;
      font-size: 24px;
    }

    p {
      font-size: 18px;

    }
  }

  .qualities {
    width: 50%;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width: 600px) {
      flex-direction: column;

    }

    * {
      color: white;
    }

    .quality {
      width: calc(50% - 20px);
      background-color: $red;
      padding: 20px;
      border-radius: 16px;
      margin: 10px;

      @media (max-width: 1024px) {
        margin: 0 10px 10px 0;

      }

      @media (max-width: 600px) {
        width: 100%;

      }

      h3 {
        margin-bottom: 10px;
      }

      p {

      }
    }

  }


}