@import "../../../../../main.module.scss";

.main {
  width: 100%;
  padding: 60px 0 0 0;


  .section1 {
    width: 100%;
    padding: 0 10vw;

    .title {

      h1 {
        margin-bottom: 20px;
      }

    }

    .wrapperContent {
      width: 100%;
      display: flex;
      @media (max-width: 750px) {
        display: block;
      }

      .contentLeft {
        width: 50%;

        @media (max-width: 750px) {
          width: 100%;
        }

        .wrapperImg {

          img {
            width: 100%;
            height: auto;
            border-radius: 16px;

          }
        }
      }

      .contentRight {
        width: 50%;
        padding-left: 10%;

        @media (max-width: 750px) {
          width: 100%;
          padding: 50px 0 0 0;
        }

        .listExcellencies {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          li {
            font-size: 18px;
            font-weight: 600;
            color: black;
          }

          hr {
            margin: 10px 0;
          }
        }


      }

    }

    .description {
      width: 50%;

      @media (max-width: 750px) {
        width: 100%;
      }

      p {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 600;
      }

    }
  }

  .section2 {
    width: 100vw;
    padding: 80px 10vw 0 10vw;
    position: relative;


    .wrapperImgs {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 2.5%;

      .wrapperOneImg {
        width: 50%;
        display: flex;
        gap: 5%;

        .wrapperFirst {
          display: flex;
          flex-direction: column;
          margin-bottom: 30%;
        }

        .wrapperLast {
          display: flex;
          flex-direction: column;
          margin-top: 30%;
        }

        img {
          width: 18vw;
          height: 26vw;
          object-fit: cover;

        }


      }

      .wrapperTwoImg {
        width: 50%;
        display: flex;
        gap: 5%;

        .wrapperFirst {
          display: flex;
          flex-direction: column;
          margin-bottom: 30%;

        }

        .wrapperLast {
          display: flex;
          flex-direction: column;
          margin-top: 30%;

        }

        img {
          width: 18vw;
          height: 26vw;
          object-fit: cover;

        }


      }

      .blueBackground {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background-color: $blue;
        z-index: -1;
      }
    }
  }

  .section3 {
    width: 100%;
    background-color: $blue;
    margin-top: -10px;
    padding-top: 50px;

    h2 {
      text-align: center;
      font-size: 42px;
      color: white;

      @media (max-width: 600px) {
        font-size: 34px;

      }

    }

    .wrapperCurd {
      min-width: 250px;
      width: 30%;
      height: auto;
      background-color: white;
      border-radius: 16px;
      padding: 20px;


      h3 {
        text-align: center;
        margin-bottom: 20px;
        min-height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }


  }

}