@import "./../../../main.module.scss";

.main {
  width: 100vw;
  height: auto;
  box-shadow: 1px 7px 18px -4px rgba(0, 0, 0, 0.51);
  padding: 10px 10px 0;

  @media (min-width: 990px) {
    padding: 10px;
  }

  * {
    color: black;
  }

  .nav {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 990px) {
      display: block;

    }

    .wrapperLogo {
      position: relative;

      a {
        display: flex;
        align-items: center;

        .logo {
          width: 50px;
          margin-right: 10px;

        }

        h1 {
          color: $red;
          font-size: 36px;
        }
      }

      .button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        border: 1px solid gray;
        color: black;
        padding: 10px 20px;
        border-radius: 8px;

        @media (min-width: 990px) {
          display: none;

        }

      }

    }
  }

}

.navItems {
  display: flex;
  justify-content: space-between;
  gap: 30px;

  a {
    color: black;
    transition: color 400ms;

    &:hover {
      color: $red;
      transition: color 400ms;
    }
  }
}

.navItemsMobile {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 0;
  transition: height 350ms ease;
  overflow: hidden;
}

.navItemsMobile a {
  margin: 10px 0;
}

.isCollapsed {
  height: 80px;
  transition: height 350ms ease;
}