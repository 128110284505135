@import "../../../../../main.module.scss";

.wrapperMain {
  width: 100vw;
  height: 600px;
  position: relative;

  .main {
    @include widthWithMarginCenter;
    @include responsive-padding-no-background;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: -1;

    }

    h2 {
      font-size: 36px;
      text-align: center;

      @media (min-width: 800px) {
        font-size: 48px;
      }
    }

    p {
      width: 80%;
      text-align: center;
      margin-bottom: 10px;
      @media (min-width: 800px) {
        font-size: 24px;
      }
    }

    button {
      border-radius: 12px;
      background-color: white;
      padding: 10px 20px;
      border: 1px solid gray;
      @media (min-width: 800px) {
        font-size: 24px;
      }
    }
  }
}