@import "../../../../../main.module.scss";

.main {
  @include widthWithMarginCenter;
  @include responsive-padding-no-background;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  h2 {
    font-size: 36px;
    text-align: center;
    @media (min-width: 800px) {
      font-size: 48px;
    }
  }

  p {
    text-align: center;
    font-size: 20px;
    @media (min-width: 800px) {
      font-size: 24px;
    }
  }

  .videoContainer {
    position: relative;
    width: 90%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
