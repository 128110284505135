@import "../../../../../main.module.scss";

.wrapperMain {
  width: 100%;
  background-color: $red;

  * {
    color: white;
  }

  .main {
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
    min-height: 700px;
    height: auto;
    @include responsive-padding-no-background;

    h2 {
      text-align: center;
      margin-bottom: 40px;
    }

    .serviceList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 876px) {
        flex-direction: column;
      }

      .service {
        display: flex;
        width: 48.5%;
        padding: 20px;
        background-color: white;
        border-radius: 18px;
        margin: 0.5%;

        @media (max-width: 876px) {
          width: 100%;
          margin: 0 0 15px 0;
        }


        .wrapperImg {
          width: 30%;

          @media (max-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: center;

          }


          img {
            width: 100%;
            height: auto;

          }

        }

        .description {
          width: 70%;
          padding-left: 20px;

          h3 {
            color: black;
          }

          p {
            color: black;
          }

        }
      }

    }

  }
}