@import "../../../../main.module";

.bg {
  position: fixed;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.modal {
  width: calc(100% - 120px);
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  z-index: 3;
}

.none {
  display: none;
}