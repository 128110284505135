@import "../../../../../main.module.scss";

.main {
  width: 100%;
  min-height: 100%;
  height: auto;
  @include responsive-padding-no-background;

  .wrapperNews {
    width: 100%;
    height: auto;
    display: flex;
    gap: 5%;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 10%;

    }

    .wrapperImg {
      width: 50%;
      @media (max-width: 1024px) {
        width: 100%;

      }

      img {
        width: 100%;
        height: 70%;
        object-fit: cover;
        border-radius: 16px;
        @media (max-width: 1024px) {
          height: 50vw;

        }
      }
    }

    .news {
      margin-bottom: 40px;
      width: 50%;
      @media (max-width: 1024px) {
        width: 100%;

      }

      .titleAndCreate {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1024px) {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 16px;
        }
        h2 {
          margin-bottom: 10px;
          font-size: 36px;
        }
        h4 {

        }
      }



      p {

      }

    }
  }

}