body {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

}

* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  list-style: none;
  margin: 0;
  padding: 0;

}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

a {
  color: white;
  text-decoration: none;

}

li {
  list-style: none;
}

ul {
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
