.main {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 60px 20px;

  h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;

  }

  p {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
}
