@import "../../../main.module.scss";

.main {
  width: 100%;
  background-color: white;
  padding: 40px;
  border-radius: 18px;
  display: flex;

  @media (max-width: 850px) {
    flex-direction: column

  }

  * {
    color: black;
    outline: none;
  }

  input {
    position: relative;
    padding: 10px;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 8px;
    font-size: 14px;
    color: black;
  }

  .warning {
    transition: border 500ms;
    border: 1px solid $red;

  }

  .first {
    width: 50%;
    padding-right: 60px;


    @media (max-width: 850px) {
      width: 100%;

    }

    @media (max-width: 500px) {
      padding-right: 0;


    }

    h2 {
      margin-bottom: 10px;
      color: black;

      @media (max-width: 500px) {
        font-size: 20px;

      }
    }

    p {
      color: gray;

      @media (max-width: 850px) {
        margin-bottom: 15px;

      }

      @media (max-width: 500px) {
        font-size: 15px;

      }

    }

  }

  .second {
    width: 50%;

    @media (max-width: 850px) {
      width: 100%;

    }

    .secondTop {
      input {
        width: 100%;
      }
    }

    .secondBottom {
      display: flex;
      margin-bottom: 10px;

      @media (max-width: 500px) {
        flex-direction: column;

      }

      .wrapperInput {
        width: 50%;

        @media (max-width: 500px) {
          width: 100%;

        }

        div {
          white-space: nowrap;
        }

        .inputDefaultStyle  {
          transition: border 500ms;
          border: 1px solid #f2f2f2;

        }

        input {
          width: 95%;

          @media (max-width: 500px) {
            width: 100%;

          }
        }

        .wrapperInputTel {
          width: 100%;
          display: flex;

          .helperInp {
            width: 25%;
            background-color: #f2f2f2;
            border-radius: 8px 0 0 8px ;
            padding: 10px 0 10px 10px;
            font-size: 14px;
            color: #757575;
          }

        }

        input[type="tel"] {
          width: 71%;
          padding: 10px 10px 10px 0;
          border-radius: 0 8px 8px 0;

          @media (max-width: 500px) {
            width: 100%;

          }

        }

        input[type="email"] {
          width: 100%;
        }

      }


    }

    .wrapperButtonWithResponse {
      display: flex;

      button {
        width: 48%;
        border: none;
        padding: 10px 0;
        border-radius: 8px;
        background-color: $red;
        color: white;
        font-size: 14px;
        margin-right: 10px;

        @media (max-width: 500px) {
          width: 100%;
          margin-top: 8px;
        }
      }

      span {
        max-width: 50%;
      }
    }

  }

}