@import "../../../../../main.module.scss";

.main {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;


  @media (max-height: 800px) {
    height: 110vh;

  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }


  * {
    color: white;
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .wrapperContent {
    width: 100%;
    bottom: 20%;
    height: 80%;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 700px) {
      bottom: 10%;
    }


    h1 {
      text-align: center;
      font-size: 60px;

      @media (max-width: 700px) {
        font-size: 50px;
      }

      @media (max-width: 600px) {
        font-size: 45px;
      }

      @media (max-width: 500px) {
        font-size: 40px;
      }


    }

    p {
      margin-top: 40px;
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 20px;
    }

    .wrapperNavigation {
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;

      @media (max-width: 700px) {
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 20px;
      }

      a {
        font-weight: 700;
        text-align: center;
        width: calc(50% - 20px);
        background-color: $red;
        border-radius: 24px;
        padding: 20px 0;
        transition: background-color 500ms;

        &:hover {
          transition: background-color 500ms;
          background-color: #ffda4d;
        }

        @media (max-width: 700px) {
          width: 100%;

        }

      }

    }
  }

}