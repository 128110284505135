.main {
  width: 200px;
  min-height: 300px;
  height: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  transition: box-shadow 500ms;

  &:hover {
    transition: box-shadow 500ms;
    box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.75);
  }


  .wrapperImg {
    height: 80%;
    width: 100%;
    margin-bottom: 10px;

    img {
      width: 100%;
      min-height: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .title {
    width: 100%;
    height: 20%;

    h3 {
      color: black;
    }

    h4 {
      color: black;
    }
  }
}