.main {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 20px;

  .title {
    font-size: 36px;
  }

  .titleDop {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .descriptionTitle {
    max-width: 900px;
    font-size: 20px;
    color: gray;

  }



  .curdReviews {
    min-width: 300px;
    width: 30%;
    height: auto;
    background-color: white;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0px -1px 3px 0px rgba(94, 94, 94, 0.75);

    transition: box-shadow 500ms;

    &:hover {
      transition: box-shadow 500ms;
      box-shadow: 0px -1px 20px 0px rgba(94, 94, 94, 0.75);
    }

    h3 {
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
      min-height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {

    }

  }


}